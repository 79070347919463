import { JwtPayload } from "jwt-decode";
import { StylesConfig } from "react-select";

export const DEFAULT_TIMEZONE = "America/Los_Angeles";
export const TRADELIST_URL = "trades.lunaristrading.co";

export enum Theme {
  DEFAULT = "theme-default",
  DARK = "theme-dark",
}

export type Settings = {
  theme: Theme;
  orderDisplayDecimals: number;
  timezone: string;
};

export enum TradelistColumnType {
  CREATED = "Created",
  EXCHANGE = "Exchange",
  PAIR = "Pair",
  PRICE = "Price",
  QUANTITY = "Quantity",
  PORTFOLIO = "Portfolio",
  CREATED_BY = "Created By",
  TRADE_UUID = "Trade UUID",
  COMMENTS = "Comments",
}

export type TradeResponse = {
  created: string;
  exchange: string;
  pair: string;
  price: number;
  quantity: number;
  portfolio?: string;
  createdBy?: string;
  tradeUuid: string;
  comments?: string;
};

export interface Auth0JwtPayload extends JwtPayload {
  permissions: string[];
}

// Utilities copied from tob-gui for display

function getAbbrevInstrumentType(type: string) {
  switch (type) {
    case "SPOT":
      return "";
    case "FUTURE":
      return "F";
    case "FUTURE_PERP":
      return "F Perp";
    case "INVERSE_FUTURE":
      return "F-Inv";
    case "INVERSE_FUTURE_PERP":
      return "F-Inv Perp";
    case "MOVE_DAY":
      return "MOVE";
    case "MOVE_WEEK":
      return "MOVE-WK";
    case "MOVE_QUARTER":
      return "MOVE-Q";
  }
}

function getAbbrevInstrument(inst: string) {
  const split = inst.split(";");
  const underlying = split[0];
  const type = getAbbrevInstrumentType(split[1]);
  if (split.length > 2) {
    const exchange = split[3];
    if (split[2] === "NONE") {
      return [underlying, type, exchange].join(" ");
    }
    const expDate = new Date(split[2]);
    const expiration = [
      expDate.getFullYear().toString(),
      (expDate.getMonth() + 1).toString().padStart(2, "0"),
      expDate.getDate().toString(),
    ].join("-");
    return [underlying, type, expiration, exchange].join(" ");
  }
  return [underlying, type].join(" ");
}

export function getAbbrevExchangePair(
  exchangePair: string,
  showExchange: boolean = false
) {
  const [tradingPair, exchangeName] = exchangePair.split(">");
  const [inst1, inst2] = tradingPair.split("/");
  const abbrev =
    getAbbrevInstrument(inst1) + " / " + getAbbrevInstrument(inst2);
  return abbrev + (showExchange ? ` (${exchangeName})` : "");
}

type SelectOption = {
  label: string
  value: string
}

export const selectStyle: StylesConfig<SelectOption, false> = {
  control: (provided, state) => {
    return {
      ...provided,
      border: "none",
      boxShadow: "none",
      width: 250,
      height: 28,
      minHeight: 28,
    };
  },
  menu: (base) => ({
    ...base,
    marginTop: 2,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  option: (base) => ({
    ...base,
    padding: "4px 6px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
};

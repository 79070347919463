import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import {
  Row,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { matchSorter } from "match-sorter";

import {
  getAbbrevExchangePair,
  Settings,
  TradelistColumnType,
  TRADELIST_URL,
  TradeResponse,
} from "../types"
import { useAuth0 } from "@auth0/auth0-react";

type TableDisplayData = string | number | null;

const DATE_FORMAT = "yyyy-MM-dd HH:mm:ss.u";

const Tradelist = (props: {
  settings: Settings;
}) => {
  const EMPTY_DATA_VAL = "-";

  const COLUMNS = [
    TradelistColumnType.CREATED,
    TradelistColumnType.EXCHANGE,
    TradelistColumnType.PAIR,
    TradelistColumnType.PRICE,
    TradelistColumnType.QUANTITY,
    TradelistColumnType.PORTFOLIO,
    TradelistColumnType.CREATED_BY,
    TradelistColumnType.TRADE_UUID,
    TradelistColumnType.COMMENTS,
  ];

  const displayDecimals = props.settings.orderDisplayDecimals;
  const timezone = props.settings.timezone;

  const [trades, setTrades] = useState<TradeResponse[]>([]);

  const processTradeUpdateMessage = (
    tradeUpdateMessage: TradeResponse[]
  ): void => {
    setTrades(tradeUpdateMessage);
  };
  
  const { getAccessTokenSilently, user } = useAuth0();

  const getAuth0AccessToken = async (scope: string): Promise<string> => {
    return await getAccessTokenSilently({
      audience: "https://api.gateway.com",
      scope: scope,
    });
  };

  const openTradeUpdateStream = async (): Promise<void> => {
    const ws = new WebSocket(`wss://${TRADELIST_URL}/ws/trades`);
    ws.onopen = async () => {
      const accessToken = await getAuth0AccessToken("read:trades");
      ws.send(JSON.stringify({
        op: "login",
        args: {
          user,
          token: accessToken,
        }
      }));
    }
    ws.onmessage = (ev: MessageEvent) => {
      const msg = JSON.parse(ev.data);
      switch (msg.op) {
        case "login":
        case "logout":
          console.log(msg);
          break;
        case "update":
          processTradeUpdateMessage(msg.data as TradeResponse[]);
          break;
        default:
          console.log(`Unsupported message type: ${msg.op}`);
      }
    };
  };

  useEffect(() => {
    openTradeUpdateStream();
  }, []);

  const getRowFromTrade = (
    trade: TradeResponse,
    columnType: TradelistColumnType
  ): TableDisplayData => {
    switch (columnType) {
      case TradelistColumnType.CREATED:
        return DateTime.fromISO(trade.created, {
          zone: "utc"
        }).setZone(timezone).toFormat(DATE_FORMAT);
      case TradelistColumnType.EXCHANGE:
        return trade.exchange;
      case TradelistColumnType.PAIR:
        return getAbbrevExchangePair(trade.pair);
      case TradelistColumnType.PRICE:
        return trade.price.toFixed(displayDecimals);
      case TradelistColumnType.QUANTITY:
        return trade.quantity.toFixed(displayDecimals);
      case TradelistColumnType.PORTFOLIO:
        return trade.portfolio || EMPTY_DATA_VAL;
      case TradelistColumnType.CREATED_BY:
        return trade.createdBy || EMPTY_DATA_VAL;
      case TradelistColumnType.TRADE_UUID:
        return trade.tradeUuid;
      case TradelistColumnType.COMMENTS:
        return trade.comments || EMPTY_DATA_VAL;
      default:
        return EMPTY_DATA_VAL;
    }
  };

  const columns = useMemo(
    () => COLUMNS.map((c) => {
      return {
        Header: c,
        accessor: c 
      }
    }),
    [props.settings]
  );

  const data = useMemo(
    () => trades.map((trade: TradeResponse) => {
      let row: { [col: string]: TableDisplayData } = {};
      columns.forEach((c) => {
        row[c.accessor] = getRowFromTrade(trade, c.accessor);
      });
      return row;
    }),
    [trades, columns]
  );

  const defaultSort = useMemo(() => [{ id: TradelistColumnType.CREATED, desc: true }], []);

  const globalFilter = (
    rows: Row<{ [column: string]: TableDisplayData }>[],
    columnIds: string[],
    filterValue: string
  ) => {
    return matchSorter(rows, filterValue, {
      threshold: matchSorter.rankings.CONTAINS,
      keys: columns.map((c) => `values.${c.accessor}`),
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      autoResetPage: false,
      globalFilter: globalFilter,
      initialState: { sortBy: defaultSort, pageIndex: 0, pageSize: 30 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 3 }}>
          <p className="header table-header">
            <b> Trades </b>
          </p>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <input
              className="input"
              placeholder="Search..."
              onChange={(e) => {
                e.preventDefault();
                setGlobalFilter(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <span> {column.render("Header")}</span>
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown className="sorted" />
                        ) : (
                          <FaSortUp className="sorted" />
                        )
                      ) : (
                        column.canSort && <HiOutlineDotsVertical />
                      )}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className="pagination"
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingBottom: "50px",
        }}
      >
        <div className={`icon-small${canPreviousPage ? "" : " disabled"}`}>
          <FiChevronsLeft
            onClick={() => {
              if (canPreviousPage) gotoPage(0);
            }}
          />
        </div>
        <div className={`icon-small${canPreviousPage ? "" : " disabled"}`}>
          <FiChevronLeft
            onClick={() => {
              if (canPreviousPage) previousPage();
            }}
          />
        </div>
        <span>
          {" "}
          <input
            className="input-small"
            type="number"
            value={pageIndex + 1}
            min={1}
            max={pageCount}
            onChange={(e) => {
              const page = +e.target.value - 1;
              if (page < 0) {
                gotoPage(0);
              } else if (page > pageCount) {
                gotoPage(pageCount - 1);
              } else {
                gotoPage(page);
              }
            }}
            style={{ width: "30px" }}
          />
          {" of "} {pageOptions.length}{" "}
        </span>{" "}
        <div className={`icon-small${canNextPage ? "" : " disabled"}`}>
          <FiChevronRight
            onClick={() => {
              if (canNextPage) nextPage();
            }}
          />
        </div>
        <div className={`icon-small${canNextPage ? "" : " disabled"}`}>
          <FiChevronsRight
            onClick={() => {
              if (canNextPage) gotoPage(pageCount - 1);
            }}
          />
        </div>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Tradelist;

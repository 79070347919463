import { FiLogIn, FiLogOut } from "react-icons/fi";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  return (
    <div>
      {isAuthenticated ? (
        <div
          className="settings"
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "6px",
          }}
        >
          <span> {user?.name} </span>
          <div className="icon">
            <FiLogOut
              onClick={() => logout({ returnTo: window.location.origin })}
            />
          </div>
        </div>
      ) : (
        <div className="icon">
          <FiLogIn onClick={() => loginWithRedirect({})} />
        </div>
      )}
    </div>
  );
};

export default LoginButton;

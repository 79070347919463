import { Settings } from "luxon";
import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DEFAULT_TIMEZONE } from "./types";

Settings.defaultZone = DEFAULT_TIMEZONE;

ReactModal.setAppElement("#root");

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-sdepf-4q.us.auth0.com"
      clientId="ttTNJuyrewUUo6NTUGXdrrsSeCiOV5yw"
      redirectUri={window.location.origin}
      audience="https://api.gateway.com"
      scope="read:trades write:trades"
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
